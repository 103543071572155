import HomePage from "../pages/home.vue";
import TestPage from "../pages/test.vue";
import NotFoundPage from '../pages/404.vue';

var routes = [
	{
		path: "/",
		name: 'Home',
		component: HomePage,
	},
	{
		path: "/test/:id",
		name: 'Test',
		component: TestPage,
	},
	{
		path: '(.*)',
		component: NotFoundPage,
	},	
];

export default routes;