<template>
	<f7-page name="home">
        <f7-navbar :sliding="false">
			<f7-nav-title>Easter Trail 2022</f7-nav-title>
		</f7-navbar>
		<f7-block v-if="mode == 'clue'">
			<p class="text-align-center">Welcome to the 2022 Easter Trail Challenge (Wookiee House Edition). As you find each clue enter them here:</p>
			<p class="text-align-center">To remind you of your clue and a little hint on what it means, <f7-link icon-f7="arrow_up_right_square" @click="popupOpened = true">tap me!!</f7-link></p>
			<p><a href="/test/hello" class="link">Test me</a></p>
		</f7-block>
		<f7-block v-if="mode != 'winner'">
			<div class="text-align-center">
				<div v-for="clue in clueList" class="tile clue">
					{{ clue }}
				</div>
			</div>
		</f7-block>
		<div v-if="mode == 'guess'">
			<div id="guesses" class="scrollable">
				<f7-block v-for="guess in guesses">
					<div class="text-align-center">
						<div v-for="(letter, index) in guess.split('')" :class="{ tile: true, correct: letter == correctWord[index] }">
							{{ letter }}
						</div>
					</div>
				</f7-block>
			</div>
			<f7-block>			
				<p class="text-align-center">Enter your guessed word here, we will show you which letters are in the correct place at the end of your guess!</p>
				<div class="text-align-center">
					<div v-for="clue in guessList" class="tile clue">
						{{ clue }}
					</div>
				</div>
			</f7-block>
		</div>
		<div class="bottom-fixed" v-if="mode != 'winner'">
			<f7-block v-if="currentClues.length < 9 || mode == 'guess'" class="keyboard">
				<div v-for="keyRow in keyboard" class="text-align-center">
					<f7-button v-for="key in keyRow" raised fill @click="press(key)">{{ key }}</f7-button>
				</div>
			</f7-block>
		</div>
		<f7-block v-if="mode == 'winner'">
			<f7-block>			
				<p class="text-align-center big">WINNER!</p>
				<div class="text-align-center">
					<div v-for="letter in correctWord" class="tile correct">
						{{ letter }}
					</div>
				</div>
				<p class="text-align-center">Your prizes await at: <b>///potato.mend.upset</b>. <f7-link icon-f7="arrow_up_right_square" @click="popupOpened = true">tap me!</f7-link></p>
			</f7-block>
			<div class="pyro">
				<div class="before"></div>
				<div class="after"></div>
			</div>
		</f7-block>
		<f7-popup class="demo-popup-swipe" :opened="popupOpened" @popup:closed="popupOpened = false" :tablet-fullscreen="true" swipe-to-close>
			<f7-page>
				<f7-navbar :title="currentClues.length < 9 ? 'Next Clue' : 'Time to play'">
					<f7-nav-right>
					<f7-link popup-close>Close</f7-link>
					</f7-nav-right>
				</f7-navbar>

				<f7-block v-if="currentClues.length < 9 || mode == 'winner'">
					<p>Your clue is:</p>
					<p><b>{{ locations[currentClues.length] }}</b> <f7-link icon-f7="doc_on_doc" @click="copyLocation">copy</f7-link></p>
					<iframe v-if="showW3w" src="https://what3words.com/" style="width: 100%; height: 70vh;"></iframe>
					<p v-else><f7-button raised fill @click="showW3w = true">Show What 3 Words</f7-button></p>
				</f7-block>
				<f7-block v-else>
					<f7-button raised fill @click="mode = 'guess'; popupOpened = false">Start Guessing</f7-button>
				</f7-block>
			</f7-page>
		</f7-popup>
	</f7-page>
</template>
<script>
import { nextTick } from 'vue'

export default {
	data() {
		return {
			mode: 'clue',
			currentClues: '',
			locations: [
				'///nuzzling.rocky.household',
				'///untruth.driveway.deodorant',
				'///reset.procured.icebergs',
				'///inspected.scoots.gradually',
				'///prom.sleepers.lofts',
				'///lurching.litters.police',
				'///humid.hexes.fade',
				'///fragment.bulge.rust',
				'///parsnips.earliest.legs',
				'///potato.mend.upset'
			],
			popupOpened: false,
			showW3w: false,
			currentGuess: '',
			keyboard: [
				[ 'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P' ],
				[ 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L' ],
				[ 'Z', 'X', 'C', 'V', 'B', 'N', 'M' ]
			],
			correctWord: [ 'S', 'C', 'R', 'A', 'M', 'B', 'L', 'E', 'D' ],
			guesses: [
			]
		}
	},
	methods: {
		async press(key) {
			console.log(key, this.correctWord.includes(key));
			if (this.correctWord.includes(key)) {		
				switch(this.mode) {
					case 'clue':
						if (!this.currentClues.includes(key)) {
							this.currentClues += key;
							this.showW3w = false;
							this.popupOpened = true;
						}
						break;
					case 'guess':
						if (!this.currentGuess.includes(key)) {
							this.currentGuess += key;
							if (this.currentGuess.length == 9) {
								this.guesses.push(this.currentGuess);
								if (this.currentGuess == this.correctWord.join('')) {
									this.mode = 'winner';
								} else {
									this.currentGuess = '';
									await nextTick();
									var element = document.getElementById("guesses");
									element.scrollTop = element.scrollHeight;
								}
							}
						}
						break;
				}
			}
		},
		async copyLocation() {
			await navigator.clipboard.writeText(this.locations[this.currentClues.length]);
		},
		mounted() {
			//fetch current orders
		}
	},
	computed: {
		clueList() {
			return this.currentClues.split('');
		},
		guessList() {
			return this.currentGuess.split('');
		}
	}
}
</script>