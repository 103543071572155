<template>
	<!-- Main Framework7 App component where we pass Framework7 params -->
	<f7-app v-bind="f7params">
		<f7-panel right reveal>
			<f7-view>
				<f7-page>
					<f7-navbar title="Right Panel"></f7-navbar>
					<f7-block>Right panel content goes here</f7-block>
				</f7-page>
			</f7-view>
		</f7-panel>
		<f7-view class="safe-areas" main url="/" :browser-history="true" browser-history-separator=""></f7-view>
	</f7-app>
</template>

<script>
	import { ref, onMounted } from 'vue';
	import { f7, f7ready } from 'framework7-vue';
	import routes from '../js/routes';

	export default {
		setup() {
			onMounted(() => {
				f7ready(() => {
					// Call F7 APIs here
				});
			});
			return {
				f7params: {
					name: "Project Thumper II",
					id: "house.wookiee.thumper",
					theme: "auto",
					panel: {
						swipe: true,
					},
					routes: routes,
					// ... other params
				}
			};
		},
	};
</script>